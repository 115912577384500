import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				Riverside Anglers Retreat
			</title>
			<meta name={"description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:title"} content={"Riverside Anglers Retreat"} />
			<meta property={"og:description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65773594fdcae00021dadf60/images/close-up-man-pouring-coffee-glass%20%281%29.jpg?v=2023-12-11T18:26:16.280Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Відчуйте світ риболовлі
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У Riverside Anglers Retreat ми пропонуємо повний набір послуг, розроблених для покращення ваших вражень від риболовлі. Незалежно від того, чи є ви досвідченим рибалкою, чи тільки починаєте, наші зручності та послуги створені так, щоб забезпечити все необхідне для незабутнього дня біля води.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						align-items="center"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 30px 0px 0px"
							color="--light"
							font="--headline3"
							sm-margin="0px 0 15px 0px"
							background="--color-lightD2"
							padding="5px 15px 5px 15px"
							border-radius="15px"
							border-color="--color-light"
						>
							Рибальські тури з гідом
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Керівництво експерта
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						ловіть рибу разом із професіоналами, які знають найкращі місця та методи.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Різноманітність риби
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						орієнтуйтеся на широкий спектр видів риби, від окуня до форелі.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Усе обладнання надається
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base" color="--dark" lg-margin="0px 0px 30px 0px">
						просто принесіть свій ентузіазм, ми подбаємо про решту.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11:19:35.976Z"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
					srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-3.jpg?v=2024-05-31T11%3A19%3A35.976Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11:19:35.960Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-1.jpg?v=2024-05-31T11%3A19%3A35.960Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--primary"
						>
							Оренда човнів
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Вибір човнів
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							виберіть гребні човни, моторні човни та понтони.
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Оренда на цілий день або на півдня
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							проведіть день, як хочете, на своїх умовах.
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Засоби безпеки в комплекті
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							гарантуємо вашу безпеку під час кожної оренди.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Hero-19">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-overflow-y="hidden"
				lg-overflow-x="hidden"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-padding="0 50px 50px 50px"
					padding="0px 80px 0px 80px"
					sm-padding="0 15px 50px 15px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						align-items="center"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 30px 0px 0px"
							color="--light"
							font="--headline3"
							sm-margin="0px 0 15px 0px"
							background="--color-lightD2"
							padding="5px 15px 5px 15px"
							border-radius="15px"
						>
							Оренда рибальського спорядження
						</Text>
					</Box>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Високоякісне спорядження
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						використовуйте першокласні вудилища, котушки та снасті.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Для всіх рівнів навичок
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						спорядження підходить для початківців і експертів.
					</Text>
					<Text margin="0px 0px 20px 0px" font="--headline3" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Поради щодо найкращого використання
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark" lg-margin="0px 0px 30px 0px">
						отримайте поради щодо найкращого спорядження для вашої техніки.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					object-position="0 0"
					padding="15px 0px 15px 15px"
					border-radius="25px 0 0 25px"
					background="--color-lightD1"
					sm-margin="0px 0px 0px 15px"
					lg-margin="0px 0px 0px 25px"
					srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11%3A19%3A35.970Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD1" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11:19:35.966Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-2.jpg?v=2024-05-31T11%3A19%3A35.966Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--primary"
						>
							Програми збереження середовища існування риб
						</Text>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline3"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Беріть участь у сталому розвитку
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							приєднуйтеся до наших зусиль щодо підтримки здорової популяції риби.
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							Хоча ми поділилися лише коротким оглядом, у Riverside Anglers Retreat є ще багато цікавого та цікавого. Ми заохочуємо вас зв’язатися з нами або відвідати наш сайт, щоб отримати повну інформацію про те, що ми пропонуємо. Наша команда завжди готова допомогти та збагатити вашу рибальську подорож.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.NewFooter1 />
	</Theme>;
});